import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { NotificationProvider } from './NotificationProvider'; // Import the NotificationProvider

ReactDOM.render(
    <NotificationProvider>
        <App />
    </NotificationProvider>,
    document.getElementById('root')
);
