import { useEffect, useState } from "react";
import { QuickServiceFinderTitles } from './common-messages'

// Phone number validation function (must be 10 digits)
export const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
};

// Email validation function
export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

// Create an enum-like object for Alert Severities
export const AlertSeverity = {
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    SUCCESS: 'success',
};

export const RemoveLoginDetails = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('roleId');
    localStorage.removeItem('userId');
};

//Common Function for Logout
export const handleLogout = () => {
    RemoveLoginDetails();
    window.location.reload();
}

//Common hook for check User is logged in or not?

export const useLoginStatus = () => {
    const [isLoggedIn, setIsloggedIn] = useState(false);

    useEffect(() => {
        const roleId = localStorage.getItem('roleId');

        if (roleId) {
            setIsloggedIn(true);
        }
        else {
            setIsloggedIn(false);
        }
    }, []);

    return isLoggedIn;
}

//Statistics Algorithm
export const formatStatisticsNumber = (num) => {
    // For numbers less than 10,000, show the actual number
    if (num < 10000) {
        return num.toString();
    }
    // For numbers between 10,000 and 100,000, use K format
    if (num >= 10000 && num < 100000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    // For numbers equal to or greater than 100,000, you can format as K+ or higher
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M+';
    }
    if (num >= 100000) {
        return (num / 1000).toFixed(0) + 'K+';
    }

    return num.toString();
}

// titleConfig.js

export const setDocumentTitle = (section) => {
    // Check if section title exists, if not only use the base title
    const title = section ? `${section} - ${QuickServiceFinderTitles.baseTitle}` : QuickServiceFinderTitles.baseTitle;
    document.title = title;
};
