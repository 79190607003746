import axios from 'axios';
import { handle401Error } from './handle401Error';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor to attach the token to every request
apiClient.interceptors.request.use(
    (config) => {
        // Retrieve token from localStorage or any other storage mechanism
        const token = localStorage.getItem('token'); // Adjust this based on where your token is stored

        // If the token exists, set it in the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);

// Interceptor to handle 401 errors globally
export const setupInterceptors = (notify) => {
    apiClient.interceptors.response.use(
        (response) => {
            return response; // Return the response if successful
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                // Handle the 401 error and stop it from propagating
                handle401Error(notify);
                // Don't throw the error again, prevent it from breaking the app
                return new Promise(() => { }); // Returning an unresolved promise will stop the error from propagating
            }

            // For other errors, reject it
            return Promise.reject(error);
        }
    );
};

export default apiClient;
